import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const ImageCTT = styled(Img)`
  position: relative;
  width: 100%;
  border-radius: 16px;
  margin-left: 24px;
  z-index: 5;
  filter: sepia(100%);
  transition: 0.5s;
  &:hover {
    filter: sepia(0%);
  }

  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
`;

function Image() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hector-acosta.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  if (!data || !data.placeholderImage
     || !data.placeholderImage.childImageSharp
     || !data.placeholderImage.childImageSharp.fluid) {
    return <div>Picture not found</div>;
  }

  return <ImageCTT fluid={data.placeholderImage.childImageSharp.fluid} />;
}

export default Image;
